$color-blue: #16405f;
$color-yellow: #edb94b;

.leve {
  .ui.attached.tabular.menu {
    border: 0;
    .item {
      text-align: center;
      display: inline-block;
      font-weight: bold;
      color: $color-blue !important; // Must use !important to override crap defaults
      border: 0;
      border-bottom: 3px solid white; }
    .active.item {
      border-bottom: 3px solid $color-yellow;
      color: $color-yellow !important; } } // Must use !important to override crap defaults
  .ui.bottom.attached.segment.tab {
    border: 0;
    .ui.input input {
      margin-bottom: 1rem; }
    h4 {
      margin-bottom: 0.5rem !important; } }

  input, label, span {
    font-size: 15px !important; }

  .align-right {
    text-align: right; } }
