$color-gray: #dededd;
$color-lightyellow: rgba(234, 173, 43, 0.3);
$color-blue: #16405f;
$color-white: #fff;

.organizzazione-capacita {

  table {
    width: 100%;

    td:last-child {
      background-color: $color-lightyellow; }

    .input-wrapper {
      min-height: 2rem;
      display: inline-block;

      span {
        line-height: 2rem; } }

    input[disabled] {
      background-color: $color-gray !important; }

    .question-table-cell {
      max-width: 150px;
      text-align: left;
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
      line-height: 1; }

    .input-percent {
      position: relative; }

    .input-percent::after {
      content: '%';
      position: absolute;
      display: block;
      right: -1.5rem;
      top: 50%;
      transform: translateY(-50%); }

    input[type='number'].input-hide {
      display: none; }

    .input-percent.input-hide::after {
      display: none; }

    .spacer-row {
      td {
        line-height: 4rem;
        position: relative;
        background-color: $color-white; } } } }

