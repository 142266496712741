.ct-toast {
  box-shadow: 2px 2px 5px #0003 !important;
  padding-left: 18px !important;
}

.ct-toast.ct-toast-success {
  border: 1px solid #6ec05f !important;
}
.ct-toast.ct-toast-info {
  border: 1px solid #1271ec !important;
}
.ct-toast.ct-toast-loading {
  border: 1px solid #0088ff !important;
}
.ct-toast.ct-toast-warn {
  border: 1px solid #fed953 !important;
}
.ct-toast.ct-toast-error {
  border: 1px solid #d60a2e !important;
}
