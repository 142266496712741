$color-blue: #16405f;
$color-yellow: #edb94b;

.indicatori {
  position: relative;
  top: -25px; }

.indicatori > .container > .row {
  padding: 0; }

.indicatori h1.ui.header {
  margin-top: 0 !important;
  line-height: 1; }

.indicatori h2.ui.header {
  font-size: 45px; }

.indicatori h3.ui.header {
  font-size: 32px;
  color: $color-blue;
  margin-top: 25px !important; }

.indicatori h4.ui.header {
  font-size: 24px;
  color: $color-blue;

  em {
    font-style: normal;
    font-weight: normal; } }

.indicatori .ui.container .ui.attached.tabular.menu {
  display: none; }

.indicatori > .container > .row.tab-menu {
  position: sticky;
  top: 50px;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: 1rem solid #dededd;
  background: #dededd;
  z-index: 99; }

.indicatori .tab-menu .ui.button {
  font-size: 18px; }

.indicatori .react-datepicker-wrapper {
  width: 110px; }

.indicatori .filters {
  .column {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;

    &:not(:first-child) .inline.field {
      text-align: right; } }

  .selection.dropdown {
    max-width: 210px;

    .text {
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis; } } }

.indicatori .ui.circular.button {
      display: inline-block;
      padding: 0;
      margin-top: 0 !important;
      width: 26px !important;
      height: 26px !important;
      background: $color-yellow !important;
      text-align: center !important;
      line-height: 26px !important;

      &:hover, &:focus {
        background: lighten($color-yellow, 8) !important;
        border-color: lighten($color-yellow, 8) !important; }

      .icon {
        color: white !important;
        font-size: 18px !important;
        line-height: 26px !important;
        margin: 0 !important;
        opacity: 1;

        &[class*="fa-"] {
          font-size: 14px !important;
          margin-right: 3px !important; } } }

.indicatori .ui.header .ui.circular.button {
  margin-left: 8px !important; }

.indicatori .indicatore-disabled {
  filter: grayscale(0.7) opacity(0.3) blur(2px); }

.indicatori .pictogram {
  .header {
    margin-bottom: 20px !important; }

  img {
    width: 300px; }

  .legend {
    margin: 20px auto;
    text-align: center;
    display: block; }

  .legend .icon {
    font-size: 18px; }

  .legend .content {
    padding-left: 0 !important;
    font-size: 18px;
    font-weight: bold;

    small {
      font-size: 12px;
      font-weight: normal;
      color: $color-blue; } }

  .legend .item:not(first-child) {
    margin-left: 0.5em; } }


.indicatori .ui.inverted.segment.gauge {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 175px;
  background-color: $color-blue;
  text-align: center;
  color: white;

  .ui.circular.button {
    position: absolute;
    top: 10px;
    right: 10px; }

  .value-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

  .value {
    font-size: 45px;
    font-weight: bold;
    line-height: 1.2;

    small {
      font-size: 18px;
      font-weight: normal; } }

  .before, .after {
    font-size: 14px; }

  .icon {
    color: white !important; }

  .description {
    font-size: 18px;
    margin-top: 15px; } }

.indicatori .pie {
  position: relative;
  display: inline-block;
  height: 180px;
  width: 180px;
  margin-right: 20px;
  padding: 40px 20px 20px;
  text-align: center;
  vertical-align: middle;

  // .ui.circular.button
  .action-buttons {
    position: absolute;
    top: 16px !important;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }

  svg {
    position: absolute;
    top: 0;
    left: 0; }

  > * {
    position: relative;
    color: $color-blue; }

  .ui.header {
    margin-bottom: 0 !important; }

  &.text-smaller .ui.header {
    font-size: 20px; }

  &.text-pulled .ui.header {
    margin-top: -12px !important; }

  .value {
    font-size: 45px;
    line-height: 1;
    font-weight: bold;

    small {
      font-size: 18px;
      font-weight: normal; } }

  .description {
    font-size: 18px; } }

.indicatori .subpie {
  position: relative;
  min-height: 180px;
  display: inline-block;
  border-left: 8px solid $color-blue;
  vertical-align: middle;

  &.vertical {
    border-left: none; }

  &.no-arrow {
    padding-top: 53px; }

  &.vertical::before {
    display: block;
    position: absolute;
    content: '';
    width: 180px;
    height: 8px;
    background-color: $color-blue; }

  &.vertical.highlight-yellow::before {
    background-color: $color-yellow; }

  &.vertical.no-arrow::before {
    display: none; }

  > .ui.circular.button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2; }

  &.vertical > .ui.circular.button {
    top: calc(55px + 8px); }

  .icon {
    font-size: 45px !important;
    color: $color-blue !important;
    line-height: 180px;
    height: 180px;
    vertical-align: top; }

  &.highlight-yellow .icon.arrow {
    position: relative;
    display: block;
    left: 90px;
    margin-top: 8px;
    color: $color-yellow !important;
    line-height: 45px;
    height: 45px;
    transform: translateX(-50%); }

  .ui.segment {
    display: inline-block;
    height: 180px;
    margin-top: 0;
    border: none;
    box-shadow: none;
    background-color: rgba(33, 133, 208, 0.1);

    .pie {
      transform: scale(0.85);
      transform-origin: top center;
      margin-right: 0;

      .ui.header {
        font-size: 18px; } } }

  &.highlight-yellow .ui.segment {
    background-color: rgba(234, 173, 43, 0.3); }

  &.highlight-transparent .ui.segment {
    background-color: transparent; } }
