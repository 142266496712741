.simulazione {
  position: relative;
  top: -25px; }

.simulazione > .container > .row {
  padding: 0; }

.simulazione > .container > .row.tab-menu {
  position: sticky;
  top: 50px;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: 1rem solid #dededd;
  background: #dededd;
  z-index: 99; }

.simulazione .tab-menu .ui.button {
  font-size: 18px; }

.simulazione h1.ui.header {
  margin-top: 0 !important;
  line-height: 1; }

.simulazione h2.ui.header {
  font-size: 45px; }

.simulazione .ui.container .ui.attached.tabular.menu {
  display: none; }

.simulazione .ui.container .leve {
  .ui.attached.tabular.menu {
    display: flex;
    a {
      flex: 1; } }
  .ui.input {
    display: inline !important;
    margin-right: 0.5rem; } }

.simulazione .ui.segment:first-child {
  margin-top: 1rem; }

.simulazione .ui.button.download-button {
  font-size: 18px;
  margin-top: 0 !important;
  position: absolute;
  right: 2rem;
  top: 6px; }

