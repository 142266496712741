$color-blue: #16405f;
$color-yellow: #edb94b;
$color-white: #fff;
$graph-height: 450px;
$color-gray: #dededd;

.organizzazione-admin-placeholder {
  text-align: center;
  color: $color-gray !important;
  padding: 3rem 0 5rem;
  h2 {
    font-size: 3rem; }
  p {
    margin: 0 !important; } }

.organizzazione {

  .reset-btn {
    position: absolute;
    right: 1rem;
    top: 2rem; }

  .thermometer-column {
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    .icon-wrapper {
      font-size: 5rem;
      .fas::before {
        color: $color-blue; } }

    p {
      margin-top: 0.5rem !important; } }

  table {
    border-collapse: collapse; }

  .visite {
    height: $graph-height; }

  .saturazione {
    height: $graph-height;
    width: 100%;
    position: absolute;
    transform: translateY(-100%); }

  .saturazione-tooltip {
    background-color: #fff;
    padding: 2px 5px;
    font-weight: 700; }

  .ui.bottom.attached.segment.active.tab {
    padding: 0;
    border-color: transparent; }

  .organizzazione-pane-menu {
    .button {
      border-color: transparent !important;
      border-bottom-width: 2px !important;
      border-radius: 0;
      box-shadow: none !important;
      color: $color-blue !important;

      &:hover,
      &.active {
        color: $color-yellow !important;
        background-color: $color-white !important; }

      &.active {
        border-bottom-color: $color_yellow !important; } } }

  .organizzazione-pane {
    padding: 0 !important;

    h3 {
      background-color: $color_yellow;
      color: $color-white;
      font-size: 1.7rem;
      line-height: 1.2; } }

  // Center all semantic abs checkboxes
  td .ui.checkbox label {
    position: relative;
    left: 50%;
    transform: translateX(-10px); } }

.organizzazione-head-caption-wrapper {
  position: relative;
  height: 4rem; }

.organizzazione-table-caption {
  position: absolute;
  transform: translateY(-50%);
  min-width: 12rem;
  top: 50%;
  right: 1rem;
  line-height: 2.5;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 4px;
  background-color: $color-blue;
  color: $color-white !important;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  font-size: 15px !important;
  font-weight: bold;
  &::after {
    position: absolute;
    content: '';
    border: 7px solid transparent;
    border-top-color: $color-blue;
    bottom: -13px;
    left: 15%; } }

