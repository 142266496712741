/*TBD CON ANNA*/
/*visualizza benchmark?*/
/*comorbidità per disease, e lieve/medie/*/
/*default alimentazione*/
/*campi non obbligatori*/

/*TBD CON MANUELE*/
/*PRIORITà VISIT BY VISIT*/
/*dinamicità domande alimenta_vbv*/
/*DB, Dominio*/
/*gestisci sovrascrizione configura*/
/*gestion pop-up: quit without saving?*/
/*se attività visita non flaggata grigiare*/

.testobianco {
  color: #ffffff !important;
}

.testobluchiaro {
  color: #2185d0 !important;
}

.testobluscuro {
  color: #16405f !important;
}

.testogiallo {
  color: #eaad2b !important;
}

.testogrigio {
  color: rgb(177, 176, 176) !important;
}

.testogrigiochiaro {
  color: rgb(222, 222, 222) !important;
}

html,
body {
  background-color: #dededd;
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  padding: 0;
}

body {
  padding: 60px 30px 20px;
  overflow-y: scroll;
}

.ui.grid {
  margin-top: 5px !important;
  margin-bottom: 20px !important;
  padding-top: 10px !important;
}

.ui.grid.as-segment {
  background-color: #ffffff !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 30px 20px 30px !important;
}

.provvisorio {
  color: #d00303 !important;
}

h1,
h2,
h3,
h4,
p {
  margin: 5px 0 15px 0 !important;
}

p {
  margin: 30px 0 5px 0 !important;
}

input {
  padding: 5px !important;
  transition: all 1s ease !important;
}

input:focus {
  border: 1px solid #eaad2b !important;
}

input:disabled {
  background-color: #cecece !important;
}

input[type='number'] {
  text-align: right !important;
  width: 80px !important;
}

input[readonly],
input[readonly] + .ui.label {
  cursor: default;
  opacity: 0.5;
}

select {
  padding: 4px;
  width: 240px;
}

select:focus {
  outline: none;
}

select {
  border-color: #edb94b !important;
}

.fields {
  margin: 0 !important;
}

.num {
  margin-left: 10px;
}
.somma {
  color: #b2b0b0;
}

.testocentrato {
  text-align: center;
}

.three {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

h1 {
  font-size: 55px !important;
  text-indent: -3px;
}

label,
p,
td,
a,
th,
input,
.simple,
.item,
span,
select {
  color: #1a5483 !important;
  font-size: 18px !important;
}

form small span {
  font-size: 14px !important;
}

td {
  font-size: 16px !important;
}

table {
  border: none !important;
}

tbody {
  height: 40px;
}

td,
th {
  border-bottom: 1px solid #cecece !important;
  border-top: none !important;
  padding: 5px 2px 5px 2px !important;
}

th {
  background-color: #ffffff !important;
  padding: 10px 2px 10px 2px !important;
}

svg + div tr:last-child td,
svg + div tr:last-child th {
  border-bottom: none !important;
}

tr.disabilitata {
  background-color: #eae8e8;
}
tr.disabilitata input {
  background-color: #eae8e8;
}
tr {
  transition: all 0.5s ease;
}

.ui.form .field > label,
.ui.form .field > div > label {
  font-weight: bold;
}

.ui.form .inline.field > label {
  line-height: 30px;
}

.ui.form .inline.field > * {
  vertical-align: middle !important;
}

.ui.labeled.input > .label {
  font-size: 1rem;
}

.ui.list .list > .item > .icon + .content,
.ui.list > .item > .icon + .content {
  width: auto;
}

/* Radio - checkbox style */

.ui.checkbox .box:before,
.ui.checkbox label:before {
  width: 22px !important;
  height: 22px !important;
  border: 1px solid #edb94b !important;
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background-color: #edb94b !important;
  border-color: #edb94b !important;
  top: 1px !important;
}

.ui.checkbox {
  line-height: 22px !important;
}

.ui.checkbox + .ui.checkbox,
.field > label + .ui.checkbox {
  margin-top: 0.5em;
}

.ui.checkbox:after {
  display: none;
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  background-color: #edb94b !important;
  content: '';
  display: inline-block !important;
  position: absolute !important;
  top: 1px !important;
  left: 0 !important;
  width: 7px !important;
  height: 10px !important;
  border: 2px solid #fff !important;
  border-left: none !important;
  border-top: none !important;
  transform: translate(7.75px, 4.5px) rotate(45deg) !important;
  border-radius: 0 !important;
}

.ui.form .required.field > .checkbox:after,
.ui.form .required.field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.fields:not(.grouped) > .field > label:after {
  color: #16405f;
}

.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
  margin-bottom: 0;
  width: 252px;
}

.ui.form .field td .ui.input {
  width: auto;
}

.ui.dropdown.selection.active > .text {
  opacity: 0.4;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  width: 22px !important;
  height: 22px !important;
  box-sizing: border-box;
}

.ui.checkbox:hover .box:before,
.ui.checkbox:hover label:before {
  border-width: 2px !important;
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background-color: #edb94b !important;
  border-color: #edb94b !important;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  box-sizing: border-box;
  background-color: #edb94b !important;
  content: '';
  display: inline-block !important;
  position: absolute !important;
  top: 1px !important;
  left: 0 !important;
  width: 7px !important;
  height: 10px !important;
  border: 2px solid #fff !important;
  border-left: none !important;
  border-top: none !important;
  transform: translate(7.75px, 4.5px) rotate(45deg) !important;
  border-radius: 0 !important;
}

.ui .checkbox:after {
  display: none;
}

/* tr:hover {
  background-color: rgba(237, 185, 75, 0.2);
} */

.footer_navigazione {
  margin: 80px 0 20px 0;
}

/*#menulaterale_configura .button, #menulaterale_alimenta .button, .footer_navigazione .button, .header_navigazione .button,*/
.button,
.circular {
  background-color: #ffffff !important;
  color: #edb94b !important;
  border: 1px solid #edb94b !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2) !important;
  margin: 5px 0 0 0 !important;
}

.button.page-action-button {
  margin-top: 21px !important;
}

.button.page-action-button i {
  font-size: 18px;
}

.ui.action.input > .button {
  margin-top: 0 !important;
  box-shadow: none !important;
}

.ui.action.input:not([class*='left action']) > input:focus {
  border-right-color: transparent !important;
}

.button.danger {
  background-color: #db2828 !important;
  color: #fff !important;
  border: none !important;
}

.button.active {
  background-color: #edb94b !important;
  color: #ffffff !important;
}

.button.danger.active {
  background-color: #db2828 !important;
  color: #fff !important;
}

.bottonenavigazione {
  margin-bottom: 10px !important;
}

#menulaterale_configura .button:hover,
#menulaterale_alimenta .button:hover,
.footer_navigazione .button:hover,
.circular:hover,
.button:hover {
  background-color: #edb94b !important;
  color: #ffffff !important;
}

.button.danger:hover {
  color: #ffffff !important;
  background-color: #9e1e1e !important;
}

.ui.icon.button.transparent-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 5px;
  font-size: 15px;
  transition: transform 0.3s ease;
}

.ui.icon.button.transparent-button:hover,
.ui.icon.button.transparent-button.active {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  transform: scale(1.15);
}

.cliccato {
  background-color: #edb94b !important;
  color: #ffffff !important;
}

.ui .checkbox {
  width: 100%;
}

.ui.button.invisible-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  line-height: 18px;
  font-size: 16px !important;
  margin-top: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.ui.button.invisible-button .icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
  line-height: 1;
  border-radius: 50%;
  transition: box-shadow 140ms ease;
  vertical-align: bottom;
}

.ui.button.invisible-button:hover,
.ui.button.invisible-button:focus {
  background: white !important;
  color: #edb94b !important;
}

.ui.button.invisible-button:hover .icon,
.ui.button.invisible-button:focus .icon {
  box-shadow: 2px 2px 4px #0004 !important;
}

tbody .ui.button.invisible-button:hover .icon,
tbody .ui.button.invisible-button:focus .icon {
  box-shadow: none !important;
}

/*MENU TOP*/
#menu {
  background-color: #ffffff;
}

#menu .menu {
  border: none !important;
}

#menu.ui.menu .item:not(.disabled) {
  font-weight: bold;
  transition: all 1s ease !important;
}

#menu.ui.menu .item:not(.disabled):hover {
  border-color: #edb94b !important;
}

.ui.menu .item.disabled,
.ui.menu .item.disabled:hover {
  color: rgba(40, 40, 40, 0.5) !important;
}

#menu.ui.menu .active.item {
  border-color: #edb94b !important;
  font-weight: bold !important;
  color: #edb94b !important;
}

.fa-bars {
  font-size: 30px;
  float: right;
  display: block;
  margin: 10px 20px 10px 20px;
}

.fa-bars:hover {
  cursor: pointer;
}

@media (min-width: 800px) {
  #hamburger {
    display: none;
  }
}
@media (max-width: 800px) {
  #menu {
    display: none;
  }
}

/*MODALI*/
.ui.modal > .close {
  top: 1rem;
  right: 0.5rem;
  color: #333;
}

.ui.dimmer {
  transition: background-color 0.4s ease;
  background-color: transparent;
}

.dimmer-fade-in .ui.dimmer {
  background-color: rgba(0, 0, 0, 0.85);
}

/*LOGIN*/

.login {
  background-color: #16405f;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .grid {
  background-color: #ffffff;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.2);
}

.login .grid {
  max-width: 420px !important;
  margin: 0 auto;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.login form {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
}

/*HOME*/

.home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .column {
  padding: 14px !important;
}

.home h1,
.home h4,
.login h4,
.obiettivo h1 {
  text-align: center;
}

.home h1,
.login h1 {
  margin-bottom: 0 !important;
}

.home p,
.obiettivo p {
  max-width: 880px;
  margin: 0 auto !important;
  display: block !important;
  line-height: 40px;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  border-top: 2px solid #dedede !important;
  padding-top: 10px;
  text-align: center;
  font-size: 24px !important;
}

#logonovartis {
  width: 100px;
  margin: 0 auto -10px;
  display: block;
}

#logobip {
  width: 30px;
  margin: 0 auto -15px;
  display: block;
}

.home small {
  font-size: 10px;
  color: #16405f;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.bottone_home {
  width: 20%;
  float: left;
  text-align: center;
  transition: all 1s ease !important;
  padding: 20px 0 0 0;
  border-bottom: 7px solid #ffffff;
}

.bottone_home:hover {
  border-bottom: 7px solid #edb94b;
}

@media (max-width: 700px) {
  .bottone_home {
    width: 100%;
  }
}

.bottone_home .image img {
  display: block;
  margin: 0 auto;
  width: 70% !important;
}

.bottone_home i {
  font-size: 250px;
}
/*OBIETTIVI*/
.obiettivo p {
  font-size: 20px !important;
  text-align: left;
}

.obiettivo img {
  width: 100px !important;
}

.credenziali-form .testogiallo {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

/*CONFIGURA -top bar*/

.steps {
  width: 100%;
  margin-top: 10px !important;
}

.steps .step {
  cursor: pointer;
  width: 20%;
  padding: 8px !important;
}

.step .title,
.step .description {
  color: #edb94b !important;
  font-size: 16px !important;
}
.steps .icon {
  color: #edb94b !important;
}

.disabled .title,
.disabled .description {
  color: #16405f !important;
}
.steps .disabled .icon {
  color: #16405f !important;
}

.steps .active .icon,
.steps .active .title,
.steps .active .description {
  color: #edb94b !important;
}

.ui.step,
.ui.steps .step {
  font-size: 1rem !important;
}

.steps .active,
.ui.steps .step.active:after {
  background-color: #ffffff !important;
}

/*CONFIGURA*/

.configura td {
  font-size: 18px !important;
}

.configura .ui.table {
  width: auto;
}

.configura .ui.table thead {
  font-weight: bold;
}

.configura .ui.table tbody {
  height: auto;
}

.configura .ui.table td:first-child {
  min-width: 230px;
}

.configura .ui.table .ui.input {
  display: inline-flex !important;
}

.configura .field-type-dynamic input {
  font-size: inherit !important;
  line-height: inherit;
}

.configura .ui.form .field:not(.inline):not(.with-uber-label) {
  margin-bottom: 40px;
}

.configura .ui.form .field:not(.inline) > label {
  margin-bottom: 9px;
}

.configura .ui.form .field:not(.inline) > label + .ui.table {
  margin-top: 9px;
}

.configura .ui.form .inline.field + .inline.field {
  margin-top: -0.5rem;
}

.configura .ui.form .inline.field + .field:not(.inline),
.configura .ui.form .with-uber-label.field + .field:not(.inline) {
  margin-top: 40px;
}

.configura .ui.form .inline.field > label {
  line-height: 1;
  min-width: 230px;
  margin-top: 0;
  font-weight: normal;
}

.configura .ui.form .inline.field > .input {
  display: inline-flex !important;
}

.configura .circular {
  margin: 21px 0 0 0 !important;
  padding: 0 !important;
  height: 40px !important;
  width: 40px !important;
  transition: all 1s ease !important;
}
.configura .circular i {
  font-size: 25px;
}

.configura h3.ui.header,
.alimenta-vbv h3.ui.header,
#alimenta_aggr h3.ui.header {
  border-top: 2px solid #eaad2b !important;
  padding-top: 9px;
  margin-bottom: 15px !important;
  font-size: 1.6rem;
}

.fa-trash-alt,
.fa-plus-circle,
.fa-share-square,
.fa-edit {
  font-size: 15px;
  transition: all 1s ease !important;
  margin-right: 5px;
}

.fa-plus-circle {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 50%;
}

.fa-plus-circle {
  font-size: 18px;
}

.fa-trash-alt.testogiallo:hover,
.fa-plus-circle:hover,
.fa-share-square:hover,
.fa-edit:hover {
  cursor: pointer;
  transform: scale(1.2);
}

input {
  border: 1px solid #cecece !important;
}

.input {
  display: block !important;
  margin-bottom: 10px;
}

table .input {
  margin-bottom: 0;
}

.riduciinput label {
  color: red !important;
  font-size: 8px !important;
  width: 30px !important;
}
.riduciinput td {
  font-size: 10px !important;
}

.riduciinput input {
  font-size: 10px !important;
  width: 30px !important;
}

/*ALIMENTA vbv*/

.alimenta-vbv p,
.alimenta-vbv span.testogiallo,
.alimenta-vbv label.testogiallo,
.alimenta-vbv .button {
  font-size: 18px !important;
  /* display: block; */
}

.alimenta-vbv .field {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}

.alimenta-vbv .field label {
  margin-bottom: 18px !important;
}

.alimenta-vbv .field .label-hint {
  display: block;
  margin-top: -16px;
  margin-bottom: 16px;
}

.alimenta-vbv .field .radio label,
.alimenta-vbv .field .checkbox label {
  margin-bottom: 10px !important;
}

.alimenta-vbv .ui.selection.dropdown {
  width: auto !important;
}

.alimenta-vbv h3 {
  font-size: 22px !important;
}

.alimenta-vbv input {
  width: 252px !important;
}

.alimenta-vbv .item {
  color: #16405f !important;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.has-loaded .provenienza,
.has-loaded .prestazione,
.has-loaded .paziente,
.has-loaded .terapia,
.has-loaded .planning {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

/*ALIMENTA AGGR*/
.fa-arrow-right {
  color: #edb94b;
}

#alimenta_aggr label {
  display: inline-block !important;
  width: 90px !important;
}

.simple {
  padding: 5px !important;
}

.ui.secondary.menu {
  min-height: 29px !important;
  box-shadow: 0 0 0 !important;
  border: 1px solid #cecece;
}

.ui.secondary.menu.fixed {
  left: 0;
  right: 0;
  width: auto;
}

.item {
  font-size: 15px !important;
}

/*.alimenta-vbv .input label{
    display: inline-block;
    width: 200px !important;

}*/

.dropdown .icon {
  color: #eaad2b !important;
}

/* KPI - Indicatori - Leve */
.colonna_kpi {
  width: 25%;
  float: left;
  text-align: center;
  padding: 20px 0 0 0;
  border-bottom: 7px solid #ffffff;
}

@media (max-width: 700px) {
  .colonna_kpi {
    width: 100% !important;
    display: block !important;
  }
}

#navigazione_kpi .button {
  width: 24.5%;
}

.main_kpi,
.main_leve {
  width: 100%;
  height: 400px;
  border: 1px solid #edb94b !important;
  border-radius: 4px;
}
.main_leve {
  margin: 20px 0 0 5px;
}

.indicatore {
  height: 150px;
  width: 32%;
  background-color: #16405f;
  border-radius: 4px;
  margin: 5px;
  float: left;
}

.indicatore i {
  font-size: 50px;
  color: #ffffff;
  font-style: normal;
}

.indicatore h3 {
  color: #ffffff;
}

@media (max-width: 700px) {
  .indicatore {
    width: 98% !important;
    display: block !important;
  }
}

.ui.selection.dropdown {
  font-size: 18px;
  color: #16405f;
  padding: 5px 2.1em 5px 5px;
  border-width: 1px;
  min-height: 33px;
  min-width: 210px;
  line-height: 1.2em;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  padding: 5px;
}

.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown {
  border-color: #eaad2b;
}

.ui.selection.active.dropdown .menu {
  border: 1px solid #edb94b !important;
  border-top: none !important;
}

.ui.selection.active.dropdown.upward .menu {
  border: 1px solid #edb94b !important;
  border-top: 1px solid #edb94b !important;
  border-bottom: none !important;
}

.ui.selection.dropdown .menu > .item {
  padding: 5px !important;
}

.ui.table tfoot th {
  text-align: center;
  border-bottom: none !important;
  padding-top: 1rem !important;
}

.ui.secondary.menu {
  border: none;
}

.nota p {
  color: #b1b0b0 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

/* ARCHIVIO */

.archivio-vbv .ui.form .field > * {
  width: 250px;
  max-width: 250px;
}
