$color-blue: #16405f;
$color-yellow: #edb94b;
$color-highlight: rgba(234, 173, 43, 0.3);

.visit-stats {

  .stat__title {
    margin-bottom: 0.5rem !important;
    color: $color-blue !important;
    font-weight: 400; }

  .stat__saturation {
    color: $color-blue !important; }

  .stat__value {
    color: $color-blue !important;
    font-size: 45px !important;
    font-weight: 700;
    margin: 0 !important; }

  .stat__icon {
    font-size: 0.8rem;
    display: inline-block;
    padding: 1px;

    &-wrapper {
      text-align: left; } }

  .data-field {
    margin-top: 0.5rem;
    padding: 1rem 2rem; }

  .data-field--highlighted {
    background-color: $color-highlight; }

  .stats-indicatori {
    min-width: 55%;

    &__select {
      text-align: left;
      padding: 0 8rem;
      margin-bottom: 0.5rem; }

    &__wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 1rem;
      margin: 2rem 0 1rem; }

    &__data {
      display: flex;
      width: 100%;
      background-color: $color-highlight;
      padding: 1.5rem 1rem;
      position: relative;
      &::before {
        position: absolute;
        display: block;
        width: 2rem;
        height: 2rem;
        border: 2rem solid transparent;
        border-bottom-color: $color-highlight;
        content: '';
        right: 5rem;
        top: -4rem; } }

    &__datum {

      &-wrapper {
        flex: 1; }

      &-title, &-datum {
        font-size: 1.7rem;
        line-height: 1;
        color: $color-blue;
        margin: 0 !important; }

      &-caption {
        display: inline-block;
        margin: 0 0 0.7rem !important;
        color: $color-blue; } } } }

